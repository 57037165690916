@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,700');

$roboto: 'Roboto Slab', Arial;

.white {
	color: $white;
}
.success, .alert {
	display: none;
}
.sp25 {
	height: 25px;
}
.core-businesses {
	cursor: pointer;
	i {
		color: $primary-color;
	}
	h4 {
		color: $primary-color;
	}
}
.presenting {
	background-color: $primary-color;
	display: table;
	height: 100%;
	.table-cell {
		display: table-cell;
		vertical-align: middle;
		p {
			color: $white;
		}
	}
}

h1, h2, h3, h4 ,h5, h6 {
	font-family: $roboto, serif;
	font-weight: 700;
}
h1 {
	color: $primary-color;
}
h3 {
	color: gray;
}
p, li, a, td, th {
	font-family: "Helvetica Neue", Arial, sans-serif;
	font-weight: 300;
}
p.lead {
	font-weight: 400;
	font-family: $roboto;
	font-style: italic;
	color: silver;
}
.band {
	padding: 50px 0px;
	background-color: #1a1a1a;
	@include vertical-gradient($primary-color, darken($primary-color, 10%));
	p {
		text-align: center;
		font-family: $roboto;
		font-size: $global-font-size * 1.25;
		color: $white;
		margin: 0px;
		.icon_mail_alt {
			font-size: $global-font-size * 2;
		}
		a {
			font-family: "Roboto Slab", Arial;
			color: $white;
			font-weight: 700;
			text-decoration: none;
		}
	}
}
.c {
	font-style: normal;
	text-shadow: 0px -1px 1px $black;
}
.line {
	height: 2px;
	width: 50%;
	clear: both;
	margin: 15px 0px;
	&.blue {
		background-color: $primary-color;
	}
	&.silver {
		background-color: silver;
	}
	&.white {
		background-color: $white;
	}
	&.centered {
		margin: 15px auto
	}
}
.button {
	font-weight: 700;
}
section {
	padding: 100px 0px;
	@include radial-gradient(white, #e5e5e5);
}
section#home, section.inzahlen {
	padding: 0px 0px !important;
}

.navigation {
	$navigation-background: $black;
	$close-color: $white;
	$nav-link-color: $white;
	z-index: 2;
	width: 100%;
	position: fixed;
	background-color: rgba($navigation-background, .95);
	padding: 15px 0px;
	.close {
		color: $close-color;
		&:hover {
			color: lighten($close-color, 20%);
		}
		@include breakpoint(medium) {
			display: none;
		}
	}
	nav {
		@include breakpoint(small only) {
			opacity: 0;
			display: none;
			position: absolute;
			top: 0px;
			left:0px;
			width: 100%;
			background-color: rgba($navigation-background, .95);
		}
		@include breakpoint(medium) {
			padding-top: 8px;
		}
		ul {
			margin: 0px;
			padding: 0px;
			@include breakpoint(small only) {
				display: table-cell;
				vertical-align: middle;
				text-align: center;
			}
			@include breakpoint(medium) {
				text-align: right;
			}
			li {
				list-style-type: none;
				@include breakpoint(small only) {
					opacity: 0;
				}
				@include breakpoint(medium) {
					display: inline-block;
				}
				a {
					color: $nav-link-color;
					transition: color .5s;
					margin: 5px 0px 5px 10px;
					font-family: $roboto;
					font-weight: 700;
					&:hover {
						color: darken($nav-link-color, 20%);
					}
					@include breakpoint(small only) {
						font-size: 26px;
					}
				}

			}

		}

	}
}
#home {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	.claimcontainer {
		padding: 10px;
		h1 {
			color: $white;
			text-shadow: 0px -1px 1px $black;
			display: inline;
		}
	}
	.core-business-button {
		background-color: rgba($white, .3);
		z-index: 1;
		margin: 5px 0px;
		font-weight: 400;
		transition: background-color .25s, color .25s;
		display: block;
		border-radius: $global-radius;
		padding: 15px 0px;
		text-align: center;
		text-shadow: 0px 1px 2px $black;
		border: 1px solid $white;
		color: $white;
		&:hover {
			text-shadow: none;
			color: $primary-color;
			background-color: rgba($white, 1);
		}
	}
}
.inzahlen {
	background-image: url('../img/inzahlen.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	.bullets {
		h1 {
			color: $white;
			text-shadow: none;
			padding: 0px;
			margin:  15px 0px 5px 0px;
			line-height: 100%;
		}
		p {
			font-family: $roboto;
			color: $white;
			font-weight: 400;
			margin: 0px;
			padding: 0px;
			line-height: 100%;
		}
	}
	.columns {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.icon {
		margin: 0px auto;
		width: 50%;
		text-align: center;
		border-radius: 100%;
		background-color: rgba($white, .50);
		border: 2px dashed $white;
		img {
			width: 50%;
		}
	}
}
#map {
	height: 500px;
}

// Slider

#home {
	.homeslider {
		height: 100%;
		width: 100%;
		.slick-list, .slick-track {
			height: 100%;
		}
		.slide {
			background-size: cover;
			background-repeat: no-repeat;
			height: 100%;
			width: 100%;
			.table {
				display: table;
				height: 100%;
				width: 100%;
				.tablecell {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}
}