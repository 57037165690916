@mixin radial-gradient($color1, $color2) {
	background: $color1;
	background: -moz-radial-gradient(center, ellipse cover, $color1 0%, $color2 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $color1), color-stop(100%, $color2));
	background: -webkit-radial-gradient(center, ellipse cover, $color1 0%, $color2 100%);
	background: -o-radial-gradient(center, ellipse cover, $color1 0%, $color2 100%);
	background: -ms-radial-gradient(center, ellipse cover, $color1 0%, $color2 100%);
	background: radial-gradient(ellipse at center, $color1 0%, $color2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
}

@mixin vertical-gradient($color1, $color2) {
	background: $color1;
	background: -moz-linear-gradient(top, $color1 0%, $color2 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, $color1), color-stop(100%, $color2));
	background: -webkit-linear-gradient(top, $color1 0%, $color2 100%);
	background: -o-linear-gradient(top, $color1 0%, $color2 100%);
	background: -ms-linear-gradient(top, $color1 0%, $color2 100%);
	background: linear-gradient(to bottom, $color1 0%, $color2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2', GradientType=0 );
}